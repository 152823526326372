/*-- color style --*/
$theme-primary-color: #231942;
$theme-secondary-color: #4045A1;
$theme-accent-one: #5d5d5d;
$theme-accent-two: #9f9f9f;
$theme-accent-three: #dddddd;
$theme-accent-four: #effaff;
$theme-color-white: #ffffff;
$theme-link-color: #008cff;
$color-green: #00a45d;
$extra-color: #ffc107;
$body-color: #e1e1e1;
/*-- font style --*/
$font-small: 0.8rem;
$font-extra-small: 0.5rem;
$font-medium: 0.875rem;
$font-10: 0.625rem;
$font-18: 1.125rem;

/*-- theme style --*/
$theme-box-shadow: 0px 3px 20px rgba($theme-secondary-color, 0.1);
$theme-text-shadow: 0 0rem 0.8rem rgba($theme-accent-one, 0.15);
$theme-border-radius: 6px;

/*-- media query --*/
$break-small: 575px;
$break-medium: 767px;
$break-large: 992px;
