// * {

//     outline: none;

// }

// html,
// body,
// ul,
// ol {
//     margin: 0;
//     padding: 0;
//     list-style: none;
// }

a {
  color: $theme-link-color;
}

a:hover,
a:active,
a:focus {
  transition: all ease-in-out 0.3s;
  color: $theme-primary-color;
}

.dropdown-item.active,
.dropdown-item:active {
  background: $theme-secondary-color;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0);
}
