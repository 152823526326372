// ######################################
// --------------------------------------
// ########### THEME COLORS #############
// --------------------------------------
// XX DO NOT CHANGE NAMING CONVENTIONS XX
// ######################################
$black-color: #000000;
$white-color: #ffffff;
$primary-color: #ba510f;
$secondary-color: #1977f2;
$primary-light: #1977f2;
$primary-light1: #1977f2;
$primary-transparent: #dc5f5f08;
$primary-transparent1: #23194208;
$primary-transparent2: #23194250;
$navlink-color: #4056A1;
$gradient1: #8fc086;
$gradient2: #111d13;

// #######################################
// EXTRA COLORS ##########################
// #######################################
$light-grey:#A8A8A8	;	
$light-blue: rgb(71, 118, 235);
$theme-blue-dark: #5e548e;
$scrollbar-bg: #d35400;
$card-purple-bg: #415d431e;
$orange: #f9c74f;
$theme-green: #00bf78;
$theme-green-transparent: #00bf7808;
$light-green1: #00bf79b3;
$transparent-green: #00bf7931;
$dark-e: #314259;
$grey: #f8fbff;
$light-red:#fcd3b5;
$light-orange:#fbc7a1;
$off-white: #e6e6e6;
$theme-blue: #57aaee;
$theme-drk-blue:#438ac5;
$pink-color: #ff69b4;
$light-pink:#fae0e4;
$dark-pink:#f7cad0;
$grey2:#dce3ea;
$dark-grey:#adb5bd;
$red-color: #d41d1d;
$green-color:#cbe5a9;
$dark-green:#b8db85;
$grey1: #cdced4;
$light-green: #f2fdfa;
$dark-e: #314259;
$transparent-red: #dc5f5f29;
$divider-color: #9e9c9c;
$weDo-bg: #f2f2f2;
$pin-bg: #dcdaff;
$light-gray: rgb(202, 202, 202);
$icon-blue: #0465e4;

// 231942
//  5e548e
// 9f86c0
// be95c4
// e0b1cb
